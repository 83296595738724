import useMediaQuery from '@mui/material/useMediaQuery';
import { GlobalShoeBoxProps } from 'components/dist/organisms/GlobalShoeBox';
import { format } from 'date-fns';
import { useRouter } from 'next/router';
import { useListener } from 'polyrhythm-react';
import { ParsedUrlQuery } from 'querystring';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ElementStatusType, SharePermissionType, ShoeboxItemResponseDto, TaskViewType } from 'src/backend';
import { QUERY_MESSAGE_ID, QUERY_MESSAGE_THREAD_ID } from 'src/constants/chat';
import { QUERY_PARAM_FORM_ELEMENT_ID, QUERY_PARAM_FULL_SCREEN_PREVIEW_FORM_ELEMENT_ID, QUERY_PARAM_NEEDS_LIST_DIALOG } from 'src/constants/form-element';
import { QUERY_PARAM_LOAN_ID, QUERY_PARAM_NEEDS_LIST_LOAN_ID } from 'src/constants/loan';
import { DOC_MIME_TYPES, PRESENTATION_MIME_TYPES, TXT_MIME_TYPES, UN_PREVIEWABLE_MIME_TYPES, XSL_MIME_TYPES, ZIP_MIME_TYPES } from 'src/constants/mimes';
import { QUERY_ANONYMOUS_SESSION_ID, QUERY_DOCUMENT_PREVIEW_ID, QUERY_ELEMENT_DOCUMENT_PREVIEW_ID, QUERY_PARAM_VIEWER_ACTIONS_DISABLED, QUERY_SHOEBOX_ITEM_PREVIEW_ID } from 'src/constants/shoebox';
import { Route } from 'src/constants/ui';
import { QUERY_PARAM_TASK_VIEW } from 'src/constants/url';
import { useMessagesContext } from 'src/contexts/messages-context';
import { useUserLoanViewType } from 'src/hooks/loans/use-user-loan-view-type';
import { useGetElements } from 'src/hooks/use-get-elements';
import { useUser } from 'src/hooks/use-user';
import { useGetAnonymousDocumentWithDownloadUrlQuery } from 'src/services/anonymousApi';
import { useGetLicenseKeysQuery } from 'src/services/appApi';
import { useGetDocumentWithDownloadUrlQuery } from 'src/services/documentApi';
import { useFindShoeBoxItemQuery, useGetLoanShoeBoxItemsQuery, useGetMyShoeBoxItemsQuery } from 'src/services/lenderShoeBoxApi';
import { useGetLoanByIdQuery } from 'src/services/loanApi';
import { useUpdateElementsMutation } from 'src/services/packageApi';
import { uploadDocument } from 'src/slices/documents';
import { getLoanFormElements } from 'src/slices/form-element';
import { useDispatch } from 'src/store';
import { FormElementV2ResponseDtoExtended } from 'src/types/formelement';
import { getPackageElementPreviousNext } from 'src/utils/form-element/get-package-element-previous-next';
import { isFormElementLocked } from 'src/utils/form-element/is-form-element-locked';
import { getMimeTypeFromFilename } from 'src/utils/get-mime-type-from-filename';
import { toast } from 'src/utils/toast';



const findNextItem = (items: ShoeboxItemResponseDto[], currentItemId: string) => {
    // if there is no current item, return null
    if (!currentItemId) return null;
    // find the index of the current item
    const currentIndex = items.findIndex((item) => item.id === currentItemId);
    // if the current item does not exist in the shoebox items, return null
    if (currentIndex === -1) return null;
    // if the current item is the last item, return null
    return items[currentIndex + 1] ?? null;
}

const findPreviousItem = (items: ShoeboxItemResponseDto[], currentItemId: string) => {
    // if there is no current item, return null
    if (!currentItemId) return null;
    // find the index of the current item
    const currentIndex = items.findIndex((item) => item.id === currentItemId);
    // if the current item does not exist in the shoebox items, return null
    if (currentIndex === -1) return null;
    // if the current item is the first item, return null
    return items[currentIndex - 1] ?? null;
}


const checkCanMimePreview = (documentMimeType: string) => {
    return ![
        ...XSL_MIME_TYPES,
        ...DOC_MIME_TYPES,
        ...TXT_MIME_TYPES,
        ...ZIP_MIME_TYPES,
        ...PRESENTATION_MIME_TYPES,
        ...UN_PREVIEWABLE_MIME_TYPES].includes(documentMimeType)
}

export const useShoeboxItemViewer = () => {
    const router = useRouter();
    const userState = useUser();
    const { loanViewType } = useUserLoanViewType();

    const pdfViewierReference = useRef(null);
    const dispatch = useDispatch();
    const messagesContext = useMessagesContext();
    const licenseKeys = useGetLicenseKeysQuery();
    const [dirtyPreviewFile, setDirtyPreviewFile] = useState<File | null>(null);
    const [updateElements] = useUpdateElementsMutation();
    const queryShoeBoxItemId = router.query[QUERY_SHOEBOX_ITEM_PREVIEW_ID] as string;
    const queryDocumentId = router.query[QUERY_DOCUMENT_PREVIEW_ID] as string;
    const elementDocumentId = router.query[QUERY_ELEMENT_DOCUMENT_PREVIEW_ID] as string;
    const elementId = router.query[QUERY_PARAM_FORM_ELEMENT_ID] as string;
    const fullScreenElementId = router.query[QUERY_PARAM_FULL_SCREEN_PREVIEW_FORM_ELEMENT_ID] as string;
    const loanId = router.query[QUERY_PARAM_LOAN_ID] as string;
    const querySessionId = router.query[QUERY_ANONYMOUS_SESSION_ID] as string;
    // if a query param named viewerActionsDisabled is present then we hide the top right actions
    const actionsDisabled = router.query[QUERY_PARAM_VIEWER_ACTIONS_DISABLED];
    // if the route is borrower portal lender
    // then  we don't want uploaded files to be submitted
    const isBorrowerPortal = router.pathname.includes(Route.BORROWER_PORTAL_LENDER);
    const isDesktop = useMediaQuery('(min-width:768px)');

    const handleUpload = useCallback(async (files: File[], { formElement, loanId }) => {
        if (files.length > 0) {
            await dispatch(uploadDocument({
                submit: !isBorrowerPortal,
                file: files[0],
                formElement,
                type: 'FormElement',
                loanId,
                isMerged: false
            }));
            toast({
                content: `${formElement.title} saved successfully.`,
                type: 'success',
            })
        }
    }, [dispatch, isBorrowerPortal]);

    const handleSavePreviewerFile = useCallback(async (formElement, loanId, file: File) => {
        setDirtyPreviewFile(null);
        await handleUpload([file], { formElement, loanId });
        // then submit
        await updateElements({
            elements: [{
                id: formElement.id,
                loanId: loanId,
                inProgress: true
            }],
            multiSelect: false
        })
        dispatch(getLoanFormElements(loanId, true));
    }, [dispatch, handleUpload, updateElements]);

    const { data: loanShoeboxItemsData = [] } = useGetLoanShoeBoxItemsQuery({
        loan: loanId
    }, {
        skip: !loanId
    });

    const elementsState = useGetElements({ loanId });
    const { data: loanData } = useGetLoanByIdQuery(loanId, {
        skip: !loanId
    });


    // try to find the element in the package info
    let element = elementsState.list?.find((element) => [elementId, fullScreenElementId].includes(element.id));
    // if we can't find it there let's look into link Info as well
    // we are using link info to share element with users so they can only
    // view it in full screen mode
    if (!element && elementsState.links && !queryShoeBoxItemId) {
        element = elementsState.links.find((element) => element.id === elementId);
    }
    let nextElement: FormElementV2ResponseDtoExtended = null, previousElement: FormElementV2ResponseDtoExtended = null;
    if (element && elementDocumentId) {
        const data = getPackageElementPreviousNext(elementsState.list, element, {
            onlyWithAnswers: true,
            onlySameFolder: true
        });
        nextElement = data.nextElement;
        previousElement = data.previousElement;
    }

    const isElementASharedLink = element?.sharedInfo.some(info => info.sharedWithUser.id === userState.user?.id && info.permissions.includes('LINK'));

    const { data: userShoeboxItems = [] } = useGetMyShoeBoxItemsQuery(null, {
        skip: !userState.user?.id
    });

    const { currentData: shoeBoxItemData, isLoading: shoeBoxItemIsLoading } = useFindShoeBoxItemQuery({
        id: queryShoeBoxItemId
    }, {
        skip: !queryShoeBoxItemId,
    })
    let finalDocumentId: string | undefined;

    if (queryDocumentId) {
        finalDocumentId = queryDocumentId;
    } else if (shoeBoxItemData?.document?.id) {
        finalDocumentId = shoeBoxItemData.document.id;
        // if we have an answer document
        // and the user has a link permission
        // then we should use the answer document
    } else if (element?.answer?.document?.id) {
        finalDocumentId = element.answer.document.id;
    } else if (element || isElementASharedLink) {
        finalDocumentId = elementDocumentId;
    }

    const {
        currentData: anonymousDocumentDAta,
        isLoading: anonymousDocumentIsLoading,
        refetch: refetchAnonymousDocumentData
    } = useGetAnonymousDocumentWithDownloadUrlQuery({
        documentId: finalDocumentId,
        session: querySessionId,
    }, {
        skip: !finalDocumentId || !querySessionId,
    })

    const { currentData: authDocumentData, isLoading: authDocumentDataIsLoading, refetch: refetchAuthDocumentData } = useGetDocumentWithDownloadUrlQuery({
        id: finalDocumentId,
    }, {
        skip: !finalDocumentId || !!querySessionId,
    })

    useListener('/package/element/viewer/refresh', () => {
        refetchAuthDocumentData();
        refetchAnonymousDocumentData();
    })

    const documentData = {
        ...anonymousDocumentDAta,
        ...authDocumentData
    }

    const onDialogOpenChange = (open: boolean) => {
        if (!open) {
            // remove the query param
            const {
                [QUERY_SHOEBOX_ITEM_PREVIEW_ID]: _,
                [QUERY_DOCUMENT_PREVIEW_ID]: __,
                [QUERY_ANONYMOUS_SESSION_ID]: ___,
                [QUERY_PARAM_VIEWER_ACTIONS_DISABLED]: ____,
                [QUERY_ELEMENT_DOCUMENT_PREVIEW_ID]: _____,
                [QUERY_MESSAGE_ID]: _______,
                [QUERY_PARAM_FULL_SCREEN_PREVIEW_FORM_ELEMENT_ID]: ______,
                ...query
            } = router.query;


            // if we have thread id in the query params
            // that means we are coming from a thread
            // and we should open the messages dialog
            // with thread id
            if (query[QUERY_MESSAGE_THREAD_ID]) {
                query[QUERY_PARAM_TASK_VIEW] = 'MESSAGE';
                query[QUERY_PARAM_NEEDS_LIST_LOAN_ID] = '';
                query[QUERY_PARAM_NEEDS_LIST_DIALOG] = '';
            }
            router.replace({
                pathname: router.pathname,
                query: {
                    ...query,
                    ...isElementASharedLink ? {
                        [QUERY_PARAM_FORM_ELEMENT_ID]: undefined
                    } : {}
                }
            },
                undefined,
                { shallow: true })
        }
    }

    const onGoToItem = (args: { id: string, type: "ITEM" | "ELEMENT", documentId: string }) => () => {
        router.push({
            pathname: router.pathname,
            query: {
                ...router.query,
                ...args.type === "ITEM" ? {
                    [QUERY_SHOEBOX_ITEM_PREVIEW_ID]: args.id
                } : {
                    [QUERY_PARAM_FORM_ELEMENT_ID]: args.id,
                    [QUERY_ELEMENT_DOCUMENT_PREVIEW_ID]: args.documentId
                }
            }
        })
    }

    const onPdfViewerDirty = () => {
        if (element && !isFormElementLocked(element, !userState.isLender)) {
            pdfViewierReference.current?.saveDirtyFile(((file: File) => {
                setDirtyPreviewFile(file);
            }));
        }
    }

    const onSaveForLaterClick = async () => {
        if (!!dirtyPreviewFile) {
            await pdfViewierReference.current?.saveDirtyFile((async (file: File) => {
                await handleSavePreviewerFile(element, loanId, file);
            }));
        }
    }

    const onLeaveUnsavedChangesClick = () => {
        if (!!dirtyPreviewFile && !!element?.id) {
            handleSavePreviewerFile(element, loanId, dirtyPreviewFile);
        }
    }

    const onAskMyLenderClick = () => {
        messagesContext.onSendMessageClick([], {
            loanId: loanId,
            recipients: [loanData?.lender.id]
        })
    }

    const onPreviewNotEnabledCloseClick = () => {
        router.push({
            pathname: router.pathname,
            query: {
                ...router.query,
                [QUERY_ELEMENT_DOCUMENT_PREVIEW_ID]: undefined,
                [QUERY_PARAM_FORM_ELEMENT_ID]: undefined,
                [QUERY_PARAM_FULL_SCREEN_PREVIEW_FORM_ELEMENT_ID]: undefined,
            }
        })
    }

    const isDialogOpen = !!queryShoeBoxItemId ||
        !!queryDocumentId ||
        !!elementDocumentId ||
        !!fullScreenElementId ||
        (!!finalDocumentId && isElementASharedLink);

    const shoeboxFile: GlobalShoeBoxProps['folders'][0]['files'][0] | null = shoeBoxItemData?.id ? {
        id: shoeBoxItemData.id,
        documentId: shoeBoxItemData.document.id,
        title: shoeBoxItemData.title,
        ownerId: shoeBoxItemData.shoeboxOwner?.id,
        documentName: shoeBoxItemData.document.name,
        loanId: shoeBoxItemData.loanRole?.loan,
        uploadedById: shoeBoxItemData.uploadedBy?.id,
        uploading: false,
        uploadProgress: 0,
        shoeboxType: shoeBoxItemData.shoeboxType,
    } : null

    const documentMimeType = getMimeTypeFromFilename(documentData?.name);

    const canPreviewInPdfViewier = checkCanMimePreview(documentMimeType);

    const shoeBoxItems = []
    if (shoeBoxItemData && loanShoeboxItemsData.some(item => item.id === shoeBoxItemData.id)) {
        shoeBoxItems.push(...loanShoeboxItemsData.filter(item => item.loanRole?.id === shoeBoxItemData.loanRole?.id));
    } else if (userShoeboxItems.some(item => item.id === shoeBoxItemData?.id)) {
        shoeBoxItems.push(...userShoeboxItems);
    }
    const nextItem = findNextItem(shoeBoxItems, shoeboxFile?.id);
    const previousItem = findPreviousItem(shoeBoxItems, shoeboxFile?.id);

    const previewData = (
        !authDocumentDataIsLoading &&
        !anonymousDocumentIsLoading &&
        !shoeBoxItemIsLoading &&
        documentData?.id)
        ? documentData
        : undefined

    useEffect(function updateElementToReviewing() {
        // if element is submitted
        // and logged in user is a manager or lead lender
        // and elementDocumentId is present
        // update it to reviewing
        if (element?.id &&
            element.status === 'SUBMITTED' &&
            elementDocumentId &&
            userState.isLender) {
            updateElements({
                multiSelect: false,
                elements: [
                    {
                        id: element.id,
                        loanId: loanId,
                        reviewing: true
                    }
                ]
            }).then(() => {
                dispatch(getLoanFormElements(loanId, true));
            })
        }
    }, [loanId, element?.id, elementDocumentId, element?.status, userState.isLender, updateElements, dispatch]);

    let title = documentData?.name;
    if (shoeBoxItemData?.title) {
        title = shoeBoxItemData.title;
    } else if (element?.title) {
        title = element.title;
    }

    const isElementPreviewNotEnabled = elementId &&
        !elementsState.isLoading &&
        !element &&
        !shoeBoxItemIsLoading &&
        !authDocumentDataIsLoading

    const isElementFullScreenPreview = !!elementDocumentId && !!elementId

    const goToNextArgs = {
        id: null,
        type: null,
        documentId: null,
        documentName: null,
        title: null
    };
    const goToPreviousArgs = {
        id: null,
        type: null,
        documentId: null,
        documentName: null,
        title: null
    }
    if (nextElement) {
        goToNextArgs.id = nextElement.id;
        goToNextArgs.type = "ELEMENT";
        goToNextArgs.documentId = nextElement.answer?.document?.id;
        goToNextArgs.documentName = nextElement.answer?.document?.name;
        goToNextArgs.title = nextElement.title;
    } else if (nextItem) {
        goToNextArgs.id = nextItem.id;
        goToNextArgs.type = "ITEM";
        goToNextArgs.title = nextItem.title;
    }
    if (previousElement) {
        goToPreviousArgs.id = previousElement.id;
        goToPreviousArgs.type = "ELEMENT";
        goToPreviousArgs.documentId = previousElement.answer?.document?.id;
        goToPreviousArgs.documentName = previousElement.answer?.document?.name;
        goToPreviousArgs.title = previousElement.title;
    } else if (previousItem) {
        goToPreviousArgs.id = previousItem.id;
        goToPreviousArgs.type = "ITEM";
        goToPreviousArgs.title = previousItem.title;
    }

    return {
        onNextItem: goToNextArgs.id && onGoToItem(goToNextArgs),
        onPreviousItem: goToPreviousArgs.id && onGoToItem(goToPreviousArgs),
        goToPreviousArgs,
        goToNextArgs,
        onDialogOpenChange,
        onPdfViewerDirty,
        onSaveForLaterClick,
        onLeaveUnsavedChangesClick,
        onAskMyLenderClick,
        onSendMessage: (element) => messagesContext.onSendMessageClick([element], {
            loanId: loanId,
            recipients: []
        }),
        onPreviewNotEnabledCloseClick,
        isElementFullScreenPreview,
        isElementASharedLink,
        pdfViewierReference,
        backToTooltip: getBackToTooltip(router.query),
        element,
        isElementPreviewNotEnabled,
        pdftronKey: licenseKeys.data?.pdftronKey,
        loanId,
        loanViewType,
        isPdfPreviewDirty: !!dirtyPreviewFile,
        documentMimeType,
        canPreviewInPdfViewier,
        isDialogOpen,
        loan: loanData,
        data: previewData,
        queryId: finalDocumentId,
        shoeBoxItemData,
        shoeboxFile,
        isDesktop,
        actionsDisabled,
        isBorrower: userState.isBorrower,
        title
    } as const;
}

const getBackToTooltip = (query: ParsedUrlQuery) => {
    const taskView = query[QUERY_PARAM_TASK_VIEW];
    if (taskView === 'UPLOAD') {
        return 'Back to Upload';
    } else if (taskView === "FILL_SIGN") {
        return "Back to Fill and Sign";
    }
    return 'Back';
}